import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  dsn: "https://f9be969172bd418bab5db3ddd3bef2c1@o4504628784332800.ingest.us.sentry.io/4504648229191680",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  environment: process.env.NODE_ENV,

  // https://github.com/getsentry/sentry-javascript/issues/6295#issuecomment-1359931115
  ignoreErrors: [
    "https://reactjs.org/docs/error-decoder.html?invariant=422", // There was an error while hydrating this Suspense boundary. Switched to client rendering.
    "https://reactjs.org/docs/error-decoder.html?invariant=423", // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root...
    "https://reactjs.org/docs/error-decoder.html?invariant=425", // Text content does not match server-rendered HTML...
    "Registration ceremony was sent an abort signal", // user did this on purpose
  ],

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
